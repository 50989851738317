import React, { useState } from 'react'

function ProductHeader({ filterProducts, uniqueCategories, Menu, setsendData }) {

    const [currentCategory, setcurrentCategory] = useState("all");
    let className = "";
    return (

        <div className="Product-Header">

            <button
                className={currentCategory === "all" ? "active" : ""}
                onClick={() => {
                    setsendData(Menu)
                    setcurrentCategory("all");
                }}
            >All</button>

            {
                uniqueCategories.map((category, key) => {
                    className = currentCategory === category ? "active" : "";
                    return <button
                        key={key}
                        className={className}
                        onClick={() => {
                            filterProducts(category);
                            setcurrentCategory(category);
                        }}
                    >{category}</button>
                })
            }

        </div>

    )
}

export default ProductHeader