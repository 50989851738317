const Menu = [

    {
        id: 1,
        name: "vadapav",
        category: "lunch",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img1.jpg"
    },

    {
        id: 2,
        name: "samosa",
        category: "breakfast",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img2.jpg"
    },

    {
        id: 3,
        name: "idli",
        category: "dinner",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img3.jpg"
    },

    {
        id: 4,
        name: "dosa",
        category: "dinner",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img4.jpg"
    },

    {
        id: 5,
        name: "ramanam",
        category: "lunch",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img5.jpg"
    },

    {
        id: 6,
        name: "rice",
        category: "dinner",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img6.jpg"
    },

    {
        id: 7,
        name: "amti",
        category: "breakfast",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "img7.jpg"
    },

    {
        id: 8,
        name: "amti",
        category: "breakfast",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "im1.png"
    },

    {
        id: 9,
        name: "amti",
        category: "breakfast",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "im4.png"
    },

    {
        id: 10,
        name: "amti",
        category: "breakfast",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "im3.jpg"
    },

    {
        id: 11,
        name: "icecream",
        category: "dessert",
        price: 70,
        desc: "ipsum dolor sit amet, consectetur adip lorem hey there this is good and you shold try this",
        img: "im3.jpg"
    },

]


export default Menu