import React from 'react';

function ProductContainer({ data }) {
  return (
    <div className='Product-Container'>
      {data.map((e, key) => (
        <div className='Product' key={key}>
          <span className='id'> {e.id} </span>
          <span className='category'> {e.category} </span>
          <span className='name'> {e.name} </span>
          <span className='desc'> {e.desc} </span>
          <div className="img">
            <img src={'../../img/' + e.img} alt={e.name} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductContainer;
