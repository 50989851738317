import React, { useState } from 'react'
import './App.css';
import ProductContainer from './Components/ProductContainer';
import ProductHeader from './Components/ProductHeader';
import Menu from './Components/MenuArray';
import Header from './Base/Header';


function App() {

  // todo sending categories
  // const uniqueCategories = (Menu.map((e) => {
  //   return e.category
  // }))

  // since the values are repeating lets use "Set"
  let uniqueCategories = new Set((Menu.map((e) => {
    return e.category
  })))
  // converting set into array 
  uniqueCategories = [...uniqueCategories];

  // todo sending menu list
  let data = Menu
  const [sendData, setsendData] = useState(data)

  const filterProducts = (category) => {

    let matchedObjects
    matchedObjects = data.filter((e) => {
      return e.category === category

      // its checking for each indidual object - 
      // if category matches the category we sent with the btn then return them 
    })
    setsendData(matchedObjects)

  }

  return (
    <div>
      <Header/>
      <ProductHeader filterProducts={filterProducts} uniqueCategories={uniqueCategories} setsendData={setsendData} Menu={Menu} />
      <ProductContainer data={sendData} />
    </div>
  )
}

export default App